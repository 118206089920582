import React from "react"

import Layout from "../components/Layout"
import SEO from "@/components/SEO"
import { StaticImage } from "gatsby-plugin-image"

type PageProps = {
  data: any
}

const Legacies: React.FC<PageProps> = ({ data }) => (
  <Layout
    sectionOne={
      <>
        <StaticImage
          src="../../static/images/2018/03/legacy.jpg"
          className="absolute inset-0 -z-[1]"
          alt="Legacies"
        />
        <div className="container text-white pt-96 pb-4 flex flex-col items-center text-center">
          <h1 className="pb-2 md:pb-8 responsive-heading uppercase">
            Legacies
          </h1>
          <div className="pt-8 flex justify-center">
            <img src="/images/down.png" alt="Down arrow" />
          </div>
        </div>
      </>
    }
  >
    <SEO title="Legacies" />
    <div className="container py-14">
      <div className="flex justify-center">
        <div className="flex flex-col gap-4 max-w-[700px]">
          <h3 className="mb-4 text-3xl uppercase">
            For more than two centuries, legacies and bequests have played a
            vital role in helping RBAI forge an <span className="text-primary">
              enviable reputation</span> for
              providing a <span className="text-primary">top quality educational experience
            </span>{" "} for boys from a wide
            range of backgrounds.
          </h3>

          <p>
            Legacy gifts from former pupils, staff and friends of RBAI have
            supported bursaries, prizes and the physical development of the
            School.
          </p>
          <p>
            To find out more about legacy giving and how you can remember RBAI
            in your will, please download our legacy brochure. If you would like
            to add RBAI to your existing will, please download the{" "}
            <a
              className="link underline hover:no-underline"
              href="/docs/Legacy-Codicil-Form.pdf"
              target="_blank"
            >
              Codicil form
            </a>
            .
          </p>
          <p>
            If you have any questions, or would like to discuss your plans in
            confidence, please contact Peter Anderson, RBAI Foundations
            Director.
          </p>
          <p>
            Telephone: ​028 9024 0461
            <br />
            Email: ​​
            <a
              className="link underline hover:no-underline"
              href="mailto:p.anderson@rbai.org.uk"
            >
              p.anderson@rbai.org.uk
            </a>
          </p>
        </div>
      </div>

      <div className="grid gap-8 md:grid-cols-3 pt-24">
        <a
          className="btn btn-secondary btn-lg font-medium capitalize"
          href="/docs/Legacy-Brochure.pdf"
          target="_blank"
        >
          Download Legacy Leaflet
        </a>

        <a
          className="btn btn-secondary btn-lg font-medium capitalize"
          href="/docs/Legacy-Codicil-Form.pdf"
          target="_blank"
        >
          Download Codicil Form
        </a>

        <a
          className="btn btn-secondary btn-lg font-medium capitalize"
          href="/docs/Legacy-Intentions-Form.pdf"
          target="_blank"
        >
          Letting us know your intentions form
        </a>
      </div>
    </div>
  </Layout>
)

export default Legacies
